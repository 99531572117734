
const pickupChoice = (data: Array<any> = [], classification = "", count = 3, skip = 0) => {
  const ary = data.map((item) => {
    //区分フィルター
    if (classification) {
      if (!item.node.classification.includes(classification)) {
        return false
      }
    }
    //参照先のコンテンツが終了している場合
    if (item.node.reference?.length > 0 && !item.node.reference[0].article) {
      return false
    }

    return item
  }).filter(item => item)
  return ary.slice(skip, count == 0 ? undefined : count)
}

export default pickupChoice