import { useStaticQuery, graphql } from 'gatsby';

const pickupGet = () => {
  const newsdataquery = useStaticQuery(graphql
    `query{
        allMicrocmsPickupHotelstop (sort: {fields: sortIndex, order: ASC},filter:{pickupHotelstopId: {ne: "dummypickup"}}){
          edges {
            node {
              pdf {
                url
              }
              title
              publishedAt
              link {
                href
                blank
              }
              eyecatch {
                url
                height
                width
              }
              pickupHotelstopId
              classification
              other_label
              reference {
                fieldId
                article {
                  id
                  classification
                  title
                  eyecatch {
                    url
                    height
                    width
                  }
                }
              }
            }
          }
        }
      }`
  )
  const newsdata = newsdataquery.allMicrocmsPickupHotelstop.edges
  return newsdata
}

export default pickupGet