import React, { useState, useEffect } from "react"
import { graphql } from "gatsby";
import {
  Layout, SEO, CJumbotron, CBreadCrumb, LWrap,
  CSectTitle, CPostCard, CBtn, CAnchorNav
} from "../../components/_index"
import pickupChoice from "../../utils/pickup-choice"
import pickupGet from "../../utils/pickup-get"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  const moreNum = 12
  const pickupData = pickupChoice(pickupGet(), 'PICK UP', 0)
  const journalData = pickupChoice(pickupGet(), 'Journal', 0)
  const [pickupCount, setPickupCount] = useState(1)
  const [journalCount, setJournalCount] = useState(1)
  const moreSate = (countNum: number, arr: Array<any>): {
    count: number
    , bool: boolean
  } => {
    const count = moreNum * countNum > arr.length ? arr.length : moreNum * countNum
    const bool = arr.length != count ? true : false
    return {
      count,
      bool
    }
  }

  return (
    <Layout searchTabIndex={1}>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CJumbotron
        data={{
          title: {
            en: 'PICK UP',
            ja: 'ピックアップ'
          },
          img: {
            src: '/assets/images/pickup/kv.png'
          },
          imgSp: {
            src: '/assets/images/pickup/kv__sp.png'
          }
        }}
      />
      <CBreadCrumb data={{
        parent: [
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <div className="l_sect04">
        <LWrap>
          <CAnchorNav data={[
            {
              title: 'ピックアップ',
              link: {
                href: '#pickup'
              }
            },
            {
              title: 'ロイヤルパークホテルズJournal',
              link: {
                href: '#journal'
              }
            },
          ]} />
        </LWrap>
      </div>
      <section className="l_sect u_bgGray" id="pickup">
        <LWrap>
          <CSectTitle title={{ ja: "ピックアップ", en: "PICK UP" }} />
          <p className="c_sectLead">ロイヤルパークホテルズのおすすめプランや季節特集などをご紹介します。</p>
          <CPostCard data={pickupData.slice(0, moreSate(pickupCount, pickupData).count)} />
          {moreSate(pickupCount, pickupData).bool && (
            <p className="u_tac">
              <CBtn icon="plus" label={<>VIEW MORE</>}
                onClick={(e: any) => {
                  e.preventDefault()
                  setPickupCount((prev) => prev + 1)
                }}
                link={{
                  href: '/clickEvent/'
                }}
              />
            </p>
          )}
        </LWrap>
      </section>
      <section className="l_sect" id="journal">
        <LWrap>
          <CSectTitle title={{ ja: <>ロイヤルパークホテルズ<br className="u_sp" />Journal</>, en: "JOURNAL" }} />
          <p className="c_sectLead">お客様への思い、プランやメニューの企画背景などを、<br />外部ライターの客観的な視点を通してお届けします。</p>
          <CPostCard data={journalData.slice(0, moreSate(journalCount, journalData).count)} />
          {moreSate(journalCount, journalData).bool && (
            <p className="u_tac">
              <CBtn icon="plus" label={<>VIEW MORE</>}
                onClick={(e: any) => {
                  e.preventDefault()
                  setJournalCount((prev) => prev + 1)
                }}
                link={{
                  href: '/clickEvent/'
                }}
              />
            </p>
          )}

        </LWrap>
      </section>
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`